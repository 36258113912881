import React, { Fragment } from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import formatTitle from "../../components/common/formatTitle";
import Newsletter from "../../components/newsletter/newsletter";
import Layout from "../../components/layout/layout";
import randomKey from "../../helpers/randomKey";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  mT: {
    marginTop: theme.spacing(3),
  },
  mTPlus: {
    marginTop: theme.spacing(6),
  },
  mB: {
    marginBottom: theme.spacing(3),
  },
  mBPlus: {
    marginBottom: theme.spacing(6),
  },
  em: {
    color: theme.palette.primary.light,
  },
  morePadded: {
    padding: `0 ${theme.spacing(14)}px`,
    marginBottom: theme.spacing(10),
  },
}));

const Legal = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item container direction="column" alignItems="stretch">
        <Grid
          item
          className={clsx({
            [classes.paddingRegular]: matches,
            [classes.paddingRegularMobile]: !matches,
          })}
        >
          <div className={clsx({ [classes.morePadded]: matches })}>
            <Typography variant="body2" color="textPrimary">
              Mis à jour le 22 septembre 2022
            </Typography>
            <Typography variant="h4">Mentions légales</Typography>
            <div
              style={{
                marginBottom: theme.spacing(5),
                marginTop: theme.spacing(5),
                border: "solid 1px silver",
              }}
            />
            {data.allLegalJson.nodes[0][langKey].map((d, index) => (
              <Fragment key={`all-legal-json-${randomKey()}`}>
                <Typography
                  variant="h5"
                  className={clsx(classes.mTPlus, classes.mBPlus)}
                  key={index}
                >
                  {d.title}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {formatTitle(d.content)}
                </Typography>
              </Fragment>
            ))}
          </div>
          <Newsletter />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Legal;

export const query = graphql`
  query LegalQueryFr {
    allLegalJson {
      nodes {
        fr {
          title
          content
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
